<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane v-if="batchAuth" :label="$l('elevator.batchModification', '批量修改')" name="first">
      <batch-modify-elevator></batch-modify-elevator>
    </el-tab-pane>
    <el-tab-pane v-if="faultAuth" :label="$l('elevator.faultTemplate', '故障模板')" name="second">
      <fault-list></fault-list>
    </el-tab-pane>
    <el-tab-pane v-if="floorAuth" :label="$l('elevator.floorDisplayTemplate', '楼层对照')" name="third">
      <floor-display></floor-display>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import FaultList from "@/views/fault/FaultList";
import FloorDisplay from "@/views/floorDisplay/FloorDisplayList";
import BatchModifyElevator from "@/views/elevator/elevatorSetting/BatchModifyElevator";

export default {
  components: { FaultList, FloorDisplay, BatchModifyElevator },
  data() {
    return {
      batchAuth: this.$auth(109),
      faultAuth: this.$auth(20),
      floorAuth: this.$auth(24),
      activeName: "first",
    };
  },
  mounted() {
    if (this.batchAuth) {
      this.activeName = "first";
      return;
    }
    if (this.faultAuth) {
      this.activeName = "second";
      return;
    }
    if (this.floorAuth) {
      this.activeName = "third";
      return;
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
