<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="floorDisplays">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="no" :label="$t('floorDisplay.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="name" :label="$t('floorDisplay.name')"></el-table-column>
      <el-table-column v-if="showOrgId" prop="orgName" :label="$l('elevator.belongCompany', '所属单位')"></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
          }}</el-button>
          <!--<el-button type="success" @click="handleSet(scope.row)">{{$t('floorDisplay.setting')}}</el-button>-->
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
  </div>
</template>
<script>
import VmTable from "../../components/VmTable.vue";
import EditDialog from "./FloorDisplayEdit.vue";
import auth from "@/util/auth";


export default {
  components: { EditDialog },
  data() {
    return {
      showOrgId: auth.getUsername() === "superAdmin",
      editAuth: this.$auth(23),
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("floorDisplay.floorDisplayNo") + " " + row.no + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("floorDisplays/" + row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
